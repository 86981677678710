import { useEffect, useState } from "react";
import ResponseComponent from "./response-component";
import { getcustomerStatus } from "../helper/function";

function CustomerResponseComponent() {
  const [data, setdata] = useState(null);

  const [SId, setSId] = useState(null);
  const [btn, setbtn] = useState({
    name: "issue new sanad",
    path: "/new",
    btnaction: false,
  });

  const [viewResponse, setviewResponse] = useState(false);
  const [otherStatus, setotherStatus] = useState("");

  const [error, setError] = useState(null);

  useEffect(() => {
    getcustomerStatus(
      JSON.parse(window.localStorage.getItem("reference_id"))
    ).then(
      (res) => {
        getStatusDetail(res.data[0]);
      },
      (error) => {
        setviewResponse(false);
        setError(error);
      }
    );
  }, []);
  let pn = `${JSON.parse(window.localStorage.getItem("reference_id"))}`;

  let allData = {
    pending: {
      img: "images/wait_customer.svg",
     
     

      pn: pn,
      status: "Promissory Note Sent to Customer",
      response: "Waiting for Cutomer to take action ",
    },
    expired: {
      img: "images/closed.svg",
      pn: pn,
      status: "Promissory Note Expired",
      response: "Customer Did Not Confirm P.Note on time",
    },
    closed: {
      img: "images/closed.svg",
      pn: pn,
      status: "Promissory Note Closed",
      response: "P.NOTE is Closed",
    },
    rejected: {
      img: "images/closed.svg",
      pn: pn,
      status: "Promissory Note Rejected",
      response: "Customer Refused to Sign the P.Note",
    },
    approved: {
      img: "images/signed_customer.svg",
      pn: pn,
      status: "Promissory Note Approved",
      response: "Customer Signed the P.Note",
    },
    draft: {
      img: "images/wait_customer.svg",
      pn: pn,
      status: "Promissory Note Drafted",
      response: "",
    },
    no_response: {
      img: "images/closed.svg",
      pn: pn,
      status: "Promissory Note Failed",
      response: "There is no response",
    },
    other: {
      img: "images/closed.svg",
      pn: pn,
      status: `Promissory Note Failed`,
      response: "",
    },
  };


  function getStatusDetail(res) {
    console.log(res.status);
    switch (res.status) {
      case "pending":
        setdata(allData.pending);
        setbtn({
          name: "check status",
          path: "",
          btnaction: true,
        });
        break;

      case "expired_due_date":
        setdata(allData.expired);
        setbtn({
          name: "issue new sanad",
          path: "/new",
          btnaction: false,
        });

        break;
      case "approved":
        setdata(allData.approved);
        setbtn({
          name: "issue new sanad",
          path: "/new",
          btnaction: false,
        });

        break;
      case "closed":
        setdata(allData.closed);
        setbtn({
          name: "issue new sanad",
          path: "/new",
          btnaction: false,
        });
        break;

      case "rejected_by_debtor":
        setdata(allData.rejected);
        setbtn({
          name: "issue new sanad",
          path: "/new",
          btnaction: false,
        });
        break;

      case "cancelled_by_creditor":
        setdata(allData.rejected);
        setbtn({
          name: "issue new sanad",
          path: "/new",
          btnaction: false,
        });
        break;

        case "draft":
          setdata(allData.draft);
          setbtn({
            name: "issue new sanad",
            path: "/new",
            btnaction: false,
          });
          break;
          case "no_response":
            setdata(allData.no_response);
            setbtn({
              name: "issue new sanad",
              path: "/new",
              btnaction: false,
            });
            break;
      default:
        setotherStatus(res.status);
        setdata(allData.other);
        setbtn({
          name: "issue new sanad",
          path: "/new",
          btnaction: false,
        });
    }
    setSId(res.sanad[0].number);

    setTimeout(() => setviewResponse(true), 1000);

    return true;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!viewResponse && !data & !SId) {
    return (
      <div>
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      </div>
    );
  } else {
    return <ResponseComponent data={data} btn={btn} sanadId={SId} />;
  }
}
export default CustomerResponseComponent;
