import React from 'react';
import ReactDOM from 'react-dom';
import { transitions, positions,types , Provider as AlertProvider } from 'react-alert'
import './index.css';
import App from './App';

import reportWebVitals from './reportWebVitals';

const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 7000,
  offset: '30px',
  type: types.ERROR,

  transition: transitions.SCALE
}
// the style contains only the margin given as offset
// options contains all alert given options
// message is the alert message
// close is a function that closes the alert
const AlertTemplate = ({ style, options, message, close }) => (
  <div style={style}>
    {options.type === 'info' && '!'}
    {options.type === 'success' && ':)'}
    {options.type === 'error' && ':('}
    {message}
    <button onClick={close}>X</button>
  </div>
)

ReactDOM.render(
  <AlertProvider template={AlertTemplate} {...options}>

  <React.StrictMode>
    <App />
  </React.StrictMode>
  </AlertProvider>

  
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
