import { Breadcrumb, Row, Col } from "react-bootstrap";
import Swal from 'sweetalert2'

import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Link } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

import { RadioGroup, RadioButton } from "react-radio-buttons";
import { Formik, Field, Form } from "formik";
import { citiesOptions } from "../assets/data/cities";
import { postNewSanad } from "../helper/function";
import ResponseComponent from "./response-component";

function NewSanadComponent() {
  let duration = null;
  const [durationError, setdurationError] = useState("");
  const [sanadCreated, setSanadCreated] = useState(false);
  

  useEffect(() => {
    document.querySelectorAll(".duration > div > div").forEach((el) => {
      el.addEventListener("click", function (e) {
        document.querySelectorAll(".duration > div > div").forEach((el) => {
          el.classList.remove("greenborder");
        });

        e.preventDefault();

        el.classList.add("greenborder");
      });
    });
  });

  const history = useHistory();
  let data = {
    img: "images/until_response.svg",
    pn: "",
    status: "Sanad Being Generated, Please Hold",
    response: "Do Not Close This Page",
  };

  function validatedurationOnsubmit() {
    if (!duration) {
      setdurationError("duration is required");
      return false;
    } else {
      setdurationError("");
      return true;
    }
  }

  function addMonths(date, months) {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() !== d) {
      date.setDate(0);
    }
    return date;
  }
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function validateFieldRequired(value) {
    let error;

    if (!value) {
      error = "Required";
    }
    return error;
  }
  function validateFieldRequiredNumber(value) {
 
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^\d+$/.test(value)) {
      error = "Invalid number";
    }
    return error;
  }
  function validateFieldRequiredNumberSanadAmount(value) {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[0-9]{1,5}$/g.test(value)) {
      error = "max  only 5 digits ";
    }
    return error;

  }
  function sendNewSanad(data) {
   
    setSanadCreated(true);
    let sanad = {
      debtor: {
        national_id:  data.id.toString(),
      },
      city_of_issuance: data.city,
      debtor_phone_number: data.mob.toString(),
      total_value: data.sar,
      max_approve_duration: 30,
      sanad: [
        {
          due_type: "upon request",
          due_date: formatDate(
            addMonths(new Date(), parseInt(data.dur)).toString()
          ),
          total_value: data.sar,
        },
      ],
    };

    postNewSanad(JSON.stringify(sanad))
      .then((result) => {
       

        if (result.data.code === 201000) {
          setSanadCreated(false);
          window.localStorage.setItem(
            "reference_id",
            JSON.stringify(result.data.reference_id)
          );
          setSanadCreated(false);
          window.localStorage.setItem(
            "reference_num",
            JSON.stringify(result.data.sanad[0].number)
          );
         

          history.push("/status");
        } else {
     
          result.data.detail?Swal.fire({
            title: 'Error!',
            text: `${ result.data.detail }`,
            icon: 'error',
            showConfirmButton: false,
            timer: 3000,
          }):Swal.fire({
            title: 'Error!',
            text: 'Something went wrong ',
            icon: 'error',
            showConfirmButton: false,
            timer: 3000,
          })
          setSanadCreated(false);
        }
      })

      .catch((err) => {
        console.log("============= err ==============");

        console.log(err);

        setSanadCreated(false);
      });
  }
  return (
    <div>
      {!sanadCreated ? (
        <section>
          <Breadcrumb>
            <Breadcrumb.Item to="/" className="active-link-breadcrumb">
              <span>
                {" "}
                <Link to="/">Home</Link>
              </span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Issue New Sanad</Breadcrumb.Item>
          </Breadcrumb>
          <section className="desktop-view">
            <Row>
              <Col>
                <p className="mb-20 "> Duration of the Note</p>

                <Formik
                  initialValues={{
                    dur: "",
                    city: "",
                    id: "",
                    mob: "",
                    tab: "",
                    sar: "",
                  }}
                  onSubmit={(values, actions) => {
                    values.dur = duration;

                    if (!validatedurationOnsubmit()) {
                      return false;
                    } else {
                      sendNewSanad(values);
                      actions.resetForm({
                        values: {
                          dur: "",
                          city: "",
                          id: "",
                          mob: "",
                          tab: "",
                          sar: "",
                        },
                      });
                    }
                    return;
                  }}
                >
                  {({ errors, touched, isValidating, setFieldValue }) => (
                    <Form className="mb-40">
                      <div className="duration mb-40">
                        <RadioGroup
                          horizontal
                          onChange={(val) => (duration = val)}
                        >
                          <RadioButton
                            value="12"
                            rootColor="rgba(51, 51, 51, 1)"
                            pointColor="#80bd38"
                          >
                            12 Months
                          </RadioButton>
                          <RadioButton
                            value="18"
                            rootColor="rgba(51, 51, 51, 1)"
                            pointColor="#80bd38"
                          >
                            18 Months
                          </RadioButton>
                          <RadioButton
                            value="24"
                            rootColor="rgba(51, 51, 51, 1)"
                            pointColor="#80bd38"
                          >
                            24 Months
                          </RadioButton>
                        </RadioGroup>
                        <p className="error">{durationError}</p>
                      </div>

                      <p className="text-center mb-20">
                        {" "}
                        Promissory Note Amount
                      </p>
                      <h3 className="text-center underline mb-40">
                        <span className="text-color-priColor">
                          <Field
                          type="number"
                          pattern="[0-9]*"
                            
                            placeholder="00"
                            onFocus={(e) => e.target.placeholder = ""} 
                            onBlur={(e) => e.target.placeholder = "00"}

                            name="sar"
                            validate={validateFieldRequiredNumberSanadAmount}
                            min="0"
                          />
                        </span>
                        SAR
                        {errors.sar && touched.sar && (
                          <p className="error  mb-4 text-center">
                            {errors.sar}
                          </p>
                        )}
                      </h3>

                      <h2>Customer Details</h2>
                      <div className="grid ">
                        <div className="field">
                          <label>Issuance City</label>

                          {/* <Field
                      as="select"
                      name="city"
                   
                    >
                      <option hidden defaultValue style={{ color: "#c0c0c0" }}>
                        Begin typing or select
                      </option>
                      {citiesOptions.map((el) => (
                        <option value={el.id} key={el.id}>
                          {" "}
                          {el.name}{" "}
                        </option>
                      ))}
                    </Field> */}

                          <Field
                            name="city"
                            component={Autocomplete}
                            validate={validateFieldRequired}

                            options={citiesOptions}
                            getOptionLabel={(option) => option.name}
                            style={{ width: 300 }}
                            onChange={(e, value) => {
                              setFieldValue(
                                "city",
                                value !== null ? value.id : 0
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                className="error"
                                error={touched["city"] && !!errors["city"]}
                                helperText={touched["city"] && errors["city"]}
                                variant="outlined"
                                placeholder=" Begin typing or select"
                              />
                            )}
                          />
                        </div>

                        <div className="field">
                          <label>ID / Iqama Number</label>

                          <Field
                            type="number"
                            pattern="[0-9]*"
                            placeholder="1012345678"
                            name="id"
                            validate={validateFieldRequiredNumber}
                            min="0"
                          />
                          {errors.id && touched.id && (
                            <p className="error">{errors.id}</p>
                          )}
                        </div>

                        <div className="field">
                          <label> Mobile Number</label>

                          <Field
                            type="tel"
                            placeholder="0512345678"
                            name="mob"
                            validate={validateFieldRequiredNumber}
                            min="0"
                          />
                          {errors.mob && touched.mob && (
                            <p className="error">{errors.mob}</p>
                          )}
                        </div>

                        <div className="field">
                          <label> TAB Service Request</label>

                          <Field
                          type="number"
                          pattern="[0-9]*"
                            placeholder="123456789"
                            name="tab"
                            validate={validateFieldRequiredNumber}
                            min="0"
                          />
                          {errors.tab && touched.tab && (
                            <p className="error">{errors.tab}</p>
                          )}
                        </div>
                      </div>
                      <button type="submit" className="mb-20">
                        SEND SANAD REQUEST
                      </button>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </section>
        </section>
      ) : (
        <div>
          <section className="desktop-view">
            <Row>
              <Col>
                <ResponseComponent data={data} />
              </Col>
            </Row>
          </section>
        </div>
      )}
    </div>
  );
}

export default NewSanadComponent;
