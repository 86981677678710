import axios from "axios";
import { webApiUrl } from "./env";

export const postNewSanad = (data) => {
  return axios.post(`${webApiUrl}create-sanad.php`, data);
};

export const getcustomerStatus = (rid) => {
  return axios.get(`${webApiUrl}/check-status.php?rid=${rid}`);
};
