import CustomerResponseComponent from "./customer-response-component";
import { useState } from "react";
import { Image, useImage } from "react-render-image";

function StatusComponent() {
  const [RenderCustomerView, setRenderCustomerView] = useState(false);

  let data = {
    img: "images/wait_customer.svg",
    pn: `${JSON.parse(window.localStorage.getItem("reference_id"))}`,
    status: "Promissory Note Sent to Customer",
    response: "Waiting for Cutomer to take action ",
  };
  let src = data.img;
  const { image, loaded, errored } = useImage({ src });

  let btn = {
    name: "check status",
  };
  function getStatus() {
    setRenderCustomerView(true);
  }
  let ref_num = JSON.parse(window.localStorage.getItem("reference_num"));
  let sanadId = ref_num ? ref_num : null;

  return (
    <div>
      {!RenderCustomerView ? (
        <section>
          <div className="loading-parent-relative">
            <div className="loading-relative">
              <div>
                <div className="loader-wrapper">
                  <div className="loader"></div>
                </div>
              </div>
            </div>

            <Image src={src} errored="something is wrong" />

            <Image src={src}  errored="something is wrong">
              {({ image, loaded, errored }) => {
                if (loaded) {
                  return (
                    <div>
                      <div className="flex-column d-flex justify-content-center align-items-center response">
                        <img
                          src={image.src}
                          width={image.width}
                          height={image.height}
                        />
                        <p>{data.pn}</p>
                        <p>{data.status}</p>
                        <p className="mb-40 bold">{data.response} </p>

                        {sanadId && (
                          <div className="mb-40">
                            <h4 className="bold text-center">Sanad ID</h4>
                            <p>{sanadId}</p>
                          </div>
                        )}

                        <button onClick={getStatus}>{btn.name}</button>
                      </div>
                    </div>
                  );
                } else {
                  return;
                  <div>
                    <div className="loader-wrapper">
                      <div className="loader"></div>
                    </div>
                  </div>;
                }
              }}
            </Image>
          </div>
        </section>
      ) : (
        <CustomerResponseComponent />
      )}
    </div>
  );
}

export default StatusComponent;
