import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { Formik, Field, Form } from "formik";


function HomeComponent() {
  return (
    <div>
      <section className="desktop-view">
        <Row>
          <Col>
            <div className="mb-20">
              <p className="bold large-text" style={{marginBottom:"7px"}}>
                Hello stavros.aljallal@sa.zain.com
              </p>
              <p>In here you can issue and view your issued notes.</p>
            </div>

            <Link className="button mb-20" to="/new">
              ISSUE new sanad
            </Link>
            <div className="grayed-out-disabled">
            <button className="blue-btn mb-40">Previously Issued Notes by me </button>
            <div className="search-sanad">
              <h4 className="mb-20" > <span>Search for a Ceratin Sanad Status</span></h4>
              <Formik
                initialValues={{
                  sanad_id: "",
                  ref: "",
                }}
                onSubmit={(values, actions) => {
                //  console.log(values) 

                 
                    actions.resetForm({
                      values: {
                        sanad_id: "",
                        ref: "",
                      },
                    });
                
                  return;
                }}
              >
                {({ errors, touched}) => (
                  <Form>
                    <div className="field mb-20">
                      <label>Sanad ID</label>

                      <Field
                        placeholder="XXXXXXXXXXXXXXXXXXXX"
                        name="sanad_id"
                     disabled
                        min="0"
                      />
                      {errors.sanad_id && touched.sanad_id && (
                        <p className="error">{errors.sanad_id}</p>
                      )}
                    </div>
                    <p className="text-center mb-20">or</p>
                    <div className="field">
                      <label>Business Reference</label>

                      <Field
                        placeholder="PN-32412453"
                        name="ref"
                        disabled
                        min="0"
                      />
                      {errors.sanad_id && touched.sanad_id && (
                        <p className="error">{errors.sanad_id}</p>
                      )}
                    </div>

                    <button   disabled className="mb-20">
                    Business Reference
                    </button>
                  </Form>
                )}
              </Formik>
              <p className="text-center mb-40">Logout</p>
            </div>
            </div>
          </Col>
        </Row>
      </section>
    </div>
  );
}

export default HomeComponent;
