import './App.css';
import ViewComponent from './components/view-component';
import { BrowserRouter as Router} from "react-router-dom";

import { createBrowserHistory } from "history";

export const appHistory = createBrowserHistory();


function App() {
  return (
    <div className="App">
       <Router history={appHistory}>
    <ViewComponent/>
    </Router>
  
    </div>
  );
}

export default App;
