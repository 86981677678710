export const citiesOptions=   [
  {id:1 ,name:"Riyadh"},
  {id:2 ,name:"Al Tayif"},
  {id:3 ,name:"Al Kharaj"},
  {id:4 ,name:"Al Dawadimiu "},
  {id:5 ,name:"Al Mujamaeuh"},
  {id:6 ,name:" Quayeiuh"},
  {id:7 ,name:"Wadi Al Dawasir"},
  {id:8 ,name:" Al Aflaj"},
  {id:9 ,name:" Al Zulufiu"},
  {id:10 ,name:" Hawtatan Bani Tamim "},
  {id:11 ,name:" Eafif"},
  {id:12 ,name:" Al Salil "},
  {id:13 ,name:" Darma"},
  {id: 14 ,name:" Al Muzahimih"},
  {id:15 ,name:" Ramah"},
  {id:16 ,name:" Thadiq "},
  {id:17 ,name:" Harimla"},
  {id:18 ,name:" Alhariq "},
  {id:19 ,name:" Mecca"},
  {id:20 ,name:" Jeddah"},
  {id:21 ,name:" Al Tayif"},
  {id:22 ,name:" Al Qunafdhuh"},
  {id:23 ,name:" Al Liyth "},
  {id:24 ,name:" Rabigh "},
  {id:25 ,name:" Al Jumum"},
  {id:26 ,name:" Khalis "},
  {id:27 ,name:" Al Kamil "},
  {id:28 ,name:" Runih"},
  {id:29 ,name:" Tarabah"},
  {id:30 ,name:" Medina"},
  {id:31 ,name:" Yanbae"},
  {id:32 ,name:" Al Ola "},
  {id:33 ,name:" Al Mahd "},
  {id:34 ,name:" Badr "},
  {id:35 ,name:" Khaybar "},
  {id:36 ,name:" Al Hinakia "},
  {id:37 ,name:" Buraydah "},
  {id:38 ,name:" Eanizah"},
  {id:39 ,name:" Alrus"},
  {id:40 ,name:" Al Mudhanib"},
  {id:41 ,name:" Al Bikiriuh "},
  {id:42 ,name:" Al Badayie"},
  {id:43 ,name:" Al Asyah "},
  {id:44 ,name:" Al Nubhaniuh "},
  {id:45 ,name:" Euyun Al Juaa "},
  {id:46,name:" Al Shamasiuh "},
  {id:47 ,name:" Dammam "},
  {id:48 ,name:" Al Ahsa"},
  {id:49 ,name:" Hfr Al Batn"},
  {id:50 ,name:" Al Jabil"},
  {id:51 ,name:" Al Qatif"},
  {id:52 ,name:" Al Khubar"},
  {id:53 ,name:" Al Khafji"},
  {id:54 ,name:" Ras Tanuruh"},
  {id:55 ,name:" Abqyq"},
  {id:56 ,name:" Qaryat Al Alia"},
  {id:57 ,name:" Abha"},
  {id:58 ,name:" Khamis Mushit  "},
  {id:59 ,name:" Bayshah  "},
  {id:60 ,name:" Al Namas "},
  {id:61 ,name:" Mahayil Asir "},
  {id:62 ,name:" Sarat Obaiedah "},
  {id:63 ,name:" Tuthliyt"},
  {id:64 ,name:" Rijal Almaa"},
  {id:65 ,name:" Ahd Rafidih"},
  {id:66 ,name:" Balqurn  "},
  {id:67 ,name:" Al Majariduh "},
  {id:68 ,name:" Tabuk"},
  {id:69 ,name:" Al Wajh"},
  {id:70 ,name:" Daba"},
  {id:71 ,name:" Tima"},
  {id:72,name:" Amlij"},
  {id:73 ,name:" Haql"},
  {id:74 ,name:" Hail"},
  {id:75,name:" Biqaea"},
  {id:76 ,name:" Al Ghizaluh"},
  {id:77 ,name:" Al Shannan"},
  {id:78 ,name:" Earear "},
  {id:79,name:" Rafhaa"},
  {id:80 ,name:" Tarif  "},
  {id:81 ,name:" Jayzan"},
  {id:82 ,name:" Sibyaa  "},
  {id:83 ,name:" Abu Arish "},
  {id:84 ,name:" Samith "},
  {id:85 ,name:" Al Harth  "},
  {id:86 ,name:" Damad "},
  {id:87 ,name:" Al Riyth "},
  {id:88 ,name:" Baysh  "},
  {id:89 ,name:" Farsan "},
  {id:90 ,name:" Ahd Al Masarihih"},
  {id:91 ,name:" Al Eidabiu "},
  {id:92 ,name:" Al Eariduh "},
  {id:93 ,name:" Al Darb  "},
  {id:94 ,name:" Najran  "},
  {id:95,name:" Shururuh "},
  {id:96 ,name:" Hubuwnana "},
  {id:97 ,name:" Badr Al Janub"},
  {id:98 ,name:" Yadamuh"},
  {id:99 ,name:" Thar "},
  {id:100 ,name:" Khabash "},
  {id:101 ,name:" Al Kharkhir "},
  {id:102 ,name:" Al Bahah "},
  {id:103 ,name:" Biljurshi"},
  {id:104,name:" Al Munadiq  "},
  {id:105 ,name:" Al Makhawa "},
  {id:106 ,name:" Al Eaqiq "},
  {id:107 ,name:" Quluh "},
  {id:108 ,name:" Al Quraa "},
  {id:109 ,name:" Sakaka  "},
  {id:110 ,name:" Al Quriyat  "},
  {id:111 ,name:" Dawmat Al Jundal "},
  {id:112 ,name: "Al Zahran"},
  {id:113 ,name:" Shuqara"},
  {id:114 ,name:" Muhafazat Al Ghat "},
  {id:115 ,name:" Tabirajl"},
  {id:116 ,name:" Al Mawiyuh "},
  {id:117 ,name:" Al Barak  "},
  {id:118 ,name:" Uqlat Al Suqur "},
  {id:119 ,name:" Al Eays "},
  {id:120 ,name:" Al Hayit "},
  {id:121 ,name:" Aduma "},
  {id:122 ,name:" Tanawma "},
  {id:123,name:" Zahran Al Janub  "},
  {id:124 ,name:" Mawqiq  "},
  {id:125 ,name:" Al Kharma"},
  {id:128 ,name:" Aleardiat Alshamalia "},
  {id:129 ,name:" Marrat  "},
  {id:130 ,name:" Al Hajra "},
  {id:131 ,name:" Aleardiat Aljanubia  "},
  {id:132 ,name:" Wadi Al Fare "},
  {id:133 ,name:" Al Shamli  "},
  {id:134 ,name:" Al Twal "},
  {id:135 ,name:" 5 Duriya "},
  {id:137 ,name:" Fayfaa"},
  {id:138 ,name:" Alkhubara Wa Riaduha "},
  {id:139 ,name:" Alriyn  "},
  {id:140 ,name:" Harub "},
  {id:141 ,name:" Maysan  "},
  {id:142 ,name:" Bariq  "},
  {id:143 ,name:" Harija Al "},
  {id:144 ,name:" Ghamid Alzinad "},
  {id:145 ,name:" Alddayir Bani Malik  "},
  
  ]




