import { useState } from "react";
import { Link } from "react-router-dom";
import CustomerResponseComponent from "./customer-response-component";

import { Image, useImage } from "react-render-image";

function ResponseComponent({ data, btn = null, sanadId = null }) {
  let src = data.img;

  const { image, loaded, errored } = useImage({ src });
  const [viewreloadPendingSattus, setreloadPendingSattus] = useState(false);

  async function reloadPendingSatus() {
    setreloadPendingSattus(true);
  }

  return (
    <div>
      {!viewreloadPendingSattus && (
        <div>
   <div className="loading-parent-relative">
            <div className="loading-relative">
              <div>
                <div className="loader-wrapper">
                  <div className="loader"></div>
                </div>
              </div>
            </div>           
            <Image
              src={src}
              loaded=""
              errored="something is wrong"
            />

            <Image
              src={src}
              loaded=""
              errored="something is wrong"
            >
              {({ image, loaded, errored }) => {
                if (loaded) {
                  return (
                    <div className="flex-column d-flex justify-content-center align-items-center response">
                      <img
                        src={image.src}
                        width={image.width}
                        height={image.height}
                      />

                      <p>{data.pn}</p>
                      <p style={{ marginBottom: "5px" }}>{data.status}</p>
                      <p className="mb-40 bold">{data.response}</p>

                      {sanadId && (
                        <div className="mb-40">
                          <h4 className="bold text-center">Sanad ID</h4>
                          <p>{sanadId}</p>
                        </div>
                      )}

                      {btn && !btn.btnaction && (
                        <Link className="button" to={btn.path}>
                          {btn.name}
                        </Link>
                      )}

                      {btn && btn.btnaction && (
                        <button onClick={reloadPendingSatus}>{btn.name}</button>
                      )}
                    </div>
                  );
                } else {
                  return;
                  <div>
                    <div className="loader-wrapper">
                      <div className="loader"></div>
                    </div>
                  </div>;
                }
              }}
            </Image>
          </div>
        </div>
      )}
      {viewreloadPendingSattus && <CustomerResponseComponent />}
    </div>
  );
}

export default ResponseComponent;
