import React from "react";
import { Route, Switch, Link } from "react-router-dom";
import { Container ,Row } from "react-bootstrap";
import HomeComponent from "./home-component";
import NewSanadComponent from "./new-sanad-component";
import statusComponent from "./status-component";

function ViewComponent() {
  return (
    <div>

       
      <nav>
     
      <Container>
        <Row>
       
        <div className="logo">
          <Link to="/">
            <img src="images/logo.svg" alt="" />
          </Link>
        </div>
       
        <div>
          <button>العربية</button>
        </div>
        </Row>
        </Container>
      </nav>
   
      <section >
        <Container>
          <div className="desktop-view-screen">

        
              <Switch>
                <Route exact path="/" component={HomeComponent}></Route>
                <Route exact path="/new" component={NewSanadComponent}></Route>

                <Route path="/status" component={statusComponent}></Route>
                
              </Switch>
              </div>
           
        </Container>
      </section>
      <footer>
     
        <span>Version 1.0 (325)</span>
        <span>Zain KSA© 2021</span>
        <span>BETA</span>
        
      </footer>
    </div>
  );
}

export default ViewComponent;
